body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -0.005em;
  margin: 14px 0px;
  padding: 0;

  @media only screen and (max-width: 1068px) {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -0.002em;
  }
}

h3 {
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: 0.004em;
  margin: 6px 0px 14px;

  @media only screen and (max-width: 1068px) {
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 400;
    letter-spacing: 0.009em;
    margin: 5px 0px 14px;
  }

  @media only screen and (max-width: 734px) {
    font-size: 19px;
    line-height: 1.21053;
    font-weight: 400;
    letter-spacing: 0em;
    margin: 4px 0px 14px;
  }
}

p {
  font-size: 16px;
  line-height: 1.4285914286;
  font-weight: 400;
  letter-spacing: -0.016em;
  margin: 10px 0px;
}

.buy-now-button {
  border-radius: 980px;
  text-transform: none;
  margin: 0px 10px;
}
